<template>
  <AView
    :value="value"
    :actions="actions"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCreate="onCreate"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            :label="'Roles'"
            :icon="value.view.display.icon"
            :actions="actions"
            @onCreate="onCreate"
            right
          ></FormSection>

          <RoleItemVue
            class="mt-2"
            v-for="role in roles"
            :key="role.id"
            :value="role"
            @click="onSelect"
            :active="activeRoleId === role.id"
          ></RoleItemVue>
        </v-col>
      </v-row>
    </template>
  </AView>
</template>
    
    
    
  <script>
import { mapState } from "vuex";
import RoleItemVue from "../../../../../../components/wad/organisms/roles/RoleItem.vue";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { GUI } from "../../../../GrapUI.config.js";
import { RoleCreation } from "../../app.gh.js";

export default {
  name: "RolesList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    RoleItemVue,
  },
  computed: mapState("RoleStore", ["roles", "activeRoleId"]),
  async created() {
    this.$store.dispatch("RoleStore/GetRolesList", {});
  },
  data() {
    return {
      page: 1,
      scene: GUI.scene,
      model: null,
      data: {
        services: [],
      },
      actions: [
        {
          name: "Create",
          description: "Will create new Role",
          icon: "mdi-plus",
          color: "#23842a",
          on: "onCreate",
        },
      ],
      listTimeout: undefined,
    };
  },
  methods: {
    async onCreate() {
      await this.scene.add(this.value, RoleCreation, {});
    },
    onEdit() {},
    onSelect(role) {
      this.$store.dispatch("RoleStore/SetActiveRole", {
        from: this.value,
        role,
      });
    },
  },
  watch: {
    activeServiceId(newVal) {
      if (newVal) {
        const index = this.services.findIndex((el) => el.id === newVal);
        this.active = index;
      }
    },
    startLetter(newVal) {
      if (this.listTimeout) clearTimeout(this.listTimeout);

      this.listTimeout = setTimeout(() => {
        this.$store.dispatch("RoleStore/GetRolesList", {
          startLetter: this.letters[newVal].letter,
        });
      }, 200);
    },
  },
};
</script>
    
    
    
    <style scoped lang="scss" >
.roles-list {
  height: 100%;
  width: 100%;
  // ::v-deep button {
  //   background: white !important;
  // }
}

.v-slide-group__wrapper {
  padding-top: 20px;
}
</style>