<template>
  <v-card
    :loading="loading"
    class="term-item bg-grey-gradient"
    dark
    @click="$emit('click', value)"
  >
    <div :class="{ active }" class="indicator"></div>
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div>
      <v-card-title class="term-title">
        {{ value.name }}
      </v-card-title>
      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-row class="justify-space-between">
          <v-col cols="12">
            <div class="simple-text">
              <p>{{ value.description }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="value.roleApps && value.roleApps.length">
        <v-chip
          class="ma-2"
          v-for="roleApp in value.roleApps"
          :key="roleApp.app.id"
          label
          text-color="white"
        >
          <v-icon v-if="roleApp.app.icon" left> {{ roleApp.app.icon }} </v-icon>
          {{ roleApp.app.name }}
        </v-chip>
      </v-card-actions>

      <v-expand-transition>
        <v-alert
          v-if="
            !(value.roleApps && value.roleApps.length) &&
            !(value.roleViews && value.roleViews.length) &&
            !(value.rolePermissions && value.rolePermissions.length)
          "
          class="ma-2"
          dense
          outlined
          type="error"
        >
          This is a super ROLE all users that has this role can access anything
          in the system. Please make sure that
          <strong>ONLY</strong> trusted Users have this role assigned.
        </v-alert>
      </v-expand-transition>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      loading: false,
      show: false,
    };
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>
.term-item {
  z-index: 2;
  overflow: hidden;

  .term-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .term-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }

  .indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 100%;
      transform: translate(-100%);
      background: radial-gradient(
        circle,
        rgba(205, 48, 59, 0.5) 0%,
        rgba(205, 48, 59, 0.6) 50%,
        rgba(205, 48, 59, 0.6) 63%
      );
      transition: all 0.3s;
    }

    &.active::after {
      transform: translate(0%);
    }
  }
}
</style>